/* latin-ext */
@font-face {
  font-family: 'Aboreto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../public/5DCXAKLhwDDQ4N8bpK3UAlyu.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Aboreto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../public/5DCXAKLhwDDQ4N8bpKPUAg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Belleza';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../public/0nkoC9_pNeMfhX4Bhc_4ajzr.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Belleza';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../public/0nkoC9_pNeMfhX4BhcH4ag.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Cormorant SC';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../public/0ybmGD4kxqXBmOVLG30OGwsmEBUk9xfzwDM.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Cormorant SC';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../public/0ybmGD4kxqXBmOVLG30OGwsmEBUk_hfzwDM.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* vietnamese */
@font-face {
  font-family: 'Cormorant SC';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../public/0ybmGD4kxqXBmOVLG30OGwsmEBUk9RfzwDM.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Cormorant SC';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../public/0ybmGD4kxqXBmOVLG30OGwsmEBUk9BfzwDM.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Cormorant SC';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../public/0ybmGD4kxqXBmOVLG30OGwsmEBUk-hfz.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(../public/pxiAyp8kv8JHgFVrJJLmE0tDMPKzSQ.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(../public/pxiAyp8kv8JHgFVrJJLmE0tMMPKzSQ.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(../public/pxiAyp8kv8JHgFVrJJLmE0tCMPI.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(../public/pxiGyp8kv8JHgFVrLPTucXtAKPY.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(../public/pxiGyp8kv8JHgFVrLPTufntAKPY.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(../public/pxiGyp8kv8JHgFVrLPTucHtA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(../public/pxiByp8kv8JHgFVrLFj_Z11lFc-K.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(../public/pxiByp8kv8JHgFVrLFj_Z1JlFc-K.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(../public/pxiByp8kv8JHgFVrLFj_Z1xlFQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../public/pxiEyp8kv8JHgFVrJJbecmNE.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../public/pxiEyp8kv8JHgFVrJJnecmNE.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../public/pxiEyp8kv8JHgFVrJJfecg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Tangerine';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../public/Iurd6Y5j_oScZZow4VO5srNZi5FN.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.wrapper {
  display: flow-root;
  background-color: black;
  overflow: clip;
  min-width: 300px
}

a {
  font-weight: 400;
  color: rgb(255, 195, 83);
  font-style: italic;
}

a:visited { 
  text-decoration: none; 
  color: rgb(255, 195, 83); 
}

.App {
  display: grid;
  text-align: center;
  color: black;
  width: 100%;
  height: 100%;
  place-items: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: white;
}

.Navbar {
  position: fixed;
  width: 100vw;
  top: 0px;
  left: 0%;
  margin-bottom: -80%;
  background: transparent;
  z-index: 9;
}

.Navbar-menu {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 40px;
  margin-top: 0px;
  text-align: center;
}

.Mobile {
  display: none;
}

.Link {
  width: 100%;
  height: 100%;
  color: rgb(255, 255, 255);
  font: 24px 'Poppins';
  font-weight: 100;
  text-align: center;
  padding-right: 2%;
  padding-left: 2%;
  padding-top: 1%;
  padding-bottom: 1%;
  cursor: pointer;
  transition: 0.5s;
  animation-direction: reverse;
  background-color: rgba(0, 0, 0, 0.85);
}

@media (hover: hover) {
  .Link:hover {
    background-color: rgba(0, 173, 164, 1);
    color: rgba(255, 255, 255, 0.9);
    text-decoration: underline;
  }
}

.Link:active {
  background-color: rgb(119, 255, 248);
  text-decoration: underline;
}

/* Intro */

.Intro {
  color: rgb(255, 255, 255);
  font: 'Segoe UI';
  background-repeat: no-repeat;
  height: auto;
  transform: scale(1);
}

.Intro-wrapper {
  width: 100%;
  height: 100vh;
  background-color: #000000;
  min-height: 700px;
  transform: scale(1);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.75)), url(../public/intro\ sm.webp);
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}

.Intro-header {
  font: 8rem 'Aboreto';
  font-weight: 400;
  justify-self: center;
  padding: 2%;
  padding-bottom: 0%;
  margin-top: 15%;
  filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.0));
}

.Intro-header-2 {
  color: rgb(255, 255, 255);
  font: 5rem 'Poppins';
  font-weight: 100;
  font-style: italic;
  justify-self: center;
  margin-top: -2%;
  margin-bottom: 5%;
}


/* About */

.About {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  transform: scale(1);
  margin-bottom: -20px;
  border-top: 10px solid black;
  border-bottom: 10px solid black;
}

.About-image {
  content: url(../public/waterfall-full.webp);
  max-width: 100%;
  transform: scale(1);
}

.About-bio {
  position: absolute;
  color: rgba(255, 255, 255, 0.7);
  font: 'Segoe UI';
  font-size: 1.25rem;
  background-color: rgba(0, 0, 0, .8);
  max-width: 40%;
  height: fit-content;
  max-height: 80%;
  border: 1px solid white;
  padding-right: 4%;
  padding-left: 4%;
  padding-bottom: 6%;
  top: 0%;
  right: 0%;
  transform: scale(.8);
  word-wrap:unset;
  filter: drop-shadow(0px 0px 3px #000000);
  text-align: left;
}

.About-header {
  position: relative;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto;
  color: white;
  width: fit-content;
  font: 3.5rem 'Cormorant SC';
  border-bottom: 2px solid white;
}

/* Quote */

.plato-pic {
  width: 100%;
  margin-left: -25%;
  margin-top: -19%; 
  content: url(../public/plato5.webp);
  position: absolute;
  transform: scale(.5);
  z-index: -1;
  filter: drop-shadow(10px 20px 4px #00000070) sepia(100%) contrast(75%);
}

.plato-quote {
  display: grid;
  width: 100%;
  height: auto;
  padding-bottom: 0%;
  background-repeat: no-repeat;
  background: linear-gradient(black, rgb(48, 21, 21) 10%, #ffc476 50%, rgb(48, 21, 21) 98%, transparent);
}

.quote-wrapper {
  display: grid;
  justify-self: center;
  padding-top: 1%;
  height: 100%;
  width: 100%;
  background-image: none;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  transform: scale(1);
  outline: none;  
}

.quote-words {
  left: 0; 
  right: 0; 
  margin-left: 35%; 
  margin-right: auto;
  width: 50%;
  height: 17%;
  position: absolute;
  padding: 5%;
  padding-bottom: 5%;
  margin-top: 5%;
  font: 2rem 'Belleza';
  border-radius: 25px;
  color: #ffc476;
  background-color: transparent;
  z-index: -2;
}

.quote-text {
  border-radius: 15px;
  background: rgba(0, 0, 0, 0);
}

.quotations-1 {
  position: absolute;
  margin: 0;
  margin-top: -2%;
  margin-left: -6%;
  justify-content: left;
  font: 5rem 'Belleza';
}

.quotations-2 {
  position: absolute;
  margin: 0;
  margin-top: -2%;
  margin-left: 87%;
  justify-content: right;
  font: 5rem 'Belleza';
}

.plato {
  position: absolute;
  font: 4rem 'Tangerine';
  margin-left: 80%;
  margin-top: 22%;
  color: #ffc476;
}

/* Lessons */

.Lessons {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.Teaching-header {
  color: rgba(255, 255, 255, 1);
  font: 4rem 'Belleza';
  justify-self: center;
  background-color: rgba(255, 255, 255, 0.25);
  outline: 1px solid white;
  margin-top: 30%;
  width: 200%;
  height: fit-content;
  padding: 1%;
}

.Lessons-rundown {
  font: .9rem 'Poppins';
  font-weight: 200;
  width: 75%;
  height: fit-content;
  color: #e4d099cb;
  padding: 2%;
  background-color: rgb(53, 21, 21);
  outline: 10px double rgb(36, 6, 6);
  left: 0;
  right: 0;
  margin: 0 auto;
  margin-top: 0%;
  text-align: left;
  margin-block-end: 100px;
  margin-block-start: 50px;
}

/* background-color: rgba(105, 21, 21, 0.87); */

.Lessons-wrapper {
  display: grid;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgb(10, 33, 54), black);
  justify-items: center;
}

.Lessons-grid-1 {
  width: 70%;
  margin-top: -6%;
  justify-self: center;
  justify-content: center;
  display: grid;
  margin-bottom: 25%;
}

.Lessons-grid-2 {
  justify-self: center;
  margin-top: 5%;
  display: grid;
  grid-template-columns: 50% 50%;
  width: 80%;
  height: 100%;
}

.Lessons-header {
  font: 8.5rem 'Cormorant SC';
  color: rgba(30, 144, 238, 0.6);
  transform: scale(.5);
  margin-bottom: -1%;
}

.Lessons-header-2 {
  font: 1.5rem 'Poppins';
  font-weight: 100;
  color: rgba(255, 255, 255, 0.75);
}

.Lessons-header-2-text {
  font-size: 1rem;
  padding-left: 5%;
  padding-right: 5%;
}

.Lessons-slide-wrapper {
  display: grid;
  justify-content: center;
  grid-template-columns: auto 85% auto;
  text-align: left;
}

.Lessons-slide-grid {
  border: 25px solid rgba(0, 0, 0, 0.35);
}


.Button-slide {
  margin-top: 450%;
  height: 50px;
  position: relative;
  background-color: transparent;
}

.button-background {
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-left: 20px;
  background: radial-gradient(rgba(0, 0, 0, 0.2) 50%, rgba(14, 68, 112, 0.6));
  transition: 0.75s;
}

@media (hover: hover) {
  .button-background:hover {
    background-color: rgba(0, 153, 255, 0.5);
    transform: scale(1.2);
  }
}

.button-next {
  position: absolute;
  left: 14px;
  top: 15px;
  width: 15px;
  height: 15px;
  background: radial-gradient(rgb(255, 255, 255) 10%, transparent 20%);
  border-right: 5px solid white;
  border-top: 5px solid white;
  transform: rotate(45deg);
}

#next:active {
  transition: 0s;
  transform: scale(.9);
}

#next {       
  top: 47%;
}

.button-prev {
  position: absolute;
  left: 16px;
  top: 15px;
  width: 15px;
  height: 15px;
  background: radial-gradient(rgb(255, 255, 255) 10%, transparent 20%);
  border-right: 5px solid white;
  border-top: 5px solid white;
  transform: rotate(-135deg);
  transition: 0.75s;
}

#prev {
  left: -30%;
  top: 47%;
}

#prev:active {
  transition: 0s;
  transform: scale(.9);
}

.Lessons-slide-text-0 {
  padding: 20px;
  max-width: 30%;
  margin-top: -25%;
  margin-left: 17.5%;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: rgba(255, 255, 255, 0.75);
  font-size: 1rem;
  font-family: 'Poppins';
  font-weight: 200;
  filter: drop-shadow(0px 0px 50px rgba(255, 255, 255, 0.5));
  background-color: rgba(163, 163, 163, 0.1);
}

.Lessons-slide-text-1 {
  padding: 20px;
  max-width: 60%;
  margin-top: -5%;
  margin-left: 28.25%;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: rgba(255, 255, 255, 0.75);
  font-size: 1rem;
  font-family: 'Poppins';
  font-weight: 200;
  filter: drop-shadow(0px 0px 50px rgba(255, 255, 255, 0.5));
  background-color: rgba(163, 163, 163, 0.1);
}

.Lessons-slide-text-2 {
  padding: 20px;
  max-width: 25%;
  margin-top: -25%;
  margin-left: 40%;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: rgba(255, 255, 255, 0.75);
  font-size: 1rem;
  font-family: 'Poppins';
  font-weight: 200;
  filter: drop-shadow(0px 0px 50px rgba(255, 255, 255, 0.5));
  background-color: rgba(163, 163, 163, 0.1);
}

.Lessons-slide-text-3 {
  padding: 20px;
  max-width: 80%;
  margin-top: -8%;
  margin-left: 28%;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: rgba(255, 255, 255, 0.75);
  font-size: 1rem;
  font-family: 'Poppins';
  font-weight: 200;
  filter: drop-shadow(0px 0px 50px rgba(255, 255, 255, 0.5));
  background-color: rgba(163, 163, 163, 0.1);
}

.Lessons-slide-pictures {
  margin-bottom: -4px;
}

@keyframes circle-change {
  0% {
    height: 0px;
  }
  50% {
    height: 0px;
  }
  100% {
    height: 35px;
  }
}

@keyframes text-change {
  0% {
    opacity: 0%;
  }

  50% {
    opacity: 0%;
  }

  80% {
    opacity: 100%;
  }
}

@keyframes slide-bar {
  0% {
    width: 0%;
  }

  100% {
    width: 85%;
  }
}

.Slide-progress {
  background-color: rgba(0, 0, 0, 0.75);
  display: grid;
  justify-self: left;
  width: 0%;
  height: 5px;
  margin-left: 7.5%;
  transition: 0.5s;
  animation-name: slide-bar;
  animation-duration: 13.5s;
  animation-delay: 0.25s;
  animation-timing-function: linear;
  animation-fill-mode: both;
}

.Slide-circles {
  max-height: 40px;
  min-height: 40px;
  display: grid;
  justify-self: center;
  width: 15%;
  grid-template-columns: auto auto auto auto;
  padding-top: 10px;
  gap: 10px;
  white-space: nowrap;
}

.Slide-circle-0 {
  display: grid;
  width: 16px;
}

.Slide-circle-1 {
  display: grid;
  width: 16px;
}

.Slide-circle-2 {
  display: grid;
  width: 16px;
}

.Slide-circle-3 {
  display: grid;
  width: 16px;
}

.Slide-circle-0-background {
  outline: 1px solid rgba(55, 119, 172, 0.6);
  height: 16px;
  width: 16px;
  background-color: rgba(14, 68, 112, 0.6);
  border-radius: 50%;
  cursor: pointer;
}

.Slide-circle-1-background {
  outline: 1px solid rgba(55, 119, 172, 0.6);
  height: 16px;
  width: 16px;
  background-color: rgba(14, 68, 112, 0.6);
  border-radius: 50%;
  cursor: pointer;
}

.Slide-circle-2-background {
  outline: 1px solid rgba(55, 119, 172, 0.6);
  height: 16px;
  width: 16px;
  background-color: rgba(14, 68, 112, 0.6);
  border-radius: 50%;
  cursor: pointer;
}

.Slide-circle-3-background {
  outline: 1px solid rgba(55, 119, 172, 0.6);
  height: 16px;
  width: 16px;
  background-color: rgba(14, 68, 112, 0.6);
  border-radius: 50%;
  cursor: pointer;
}

.Slide-circle-0-line {
  display: none;
  margin-top: -14px;
  margin-left: 7px;
  width: 2px;
  background-color: rgba(255, 255, 255, .5);
  height: 35px;
}

.Slide-circle-1-line {
  display: none;
  margin-top: -14px;
  margin-left: 7px;
  width: 2px;
  background-color: rgba(255, 255, 255, .5);
  height: 35px;
}

.Slide-circle-2-line {
  display: none;
  margin-top: -14px;
  margin-left: 7px;
  width: 2px;
  background-color: rgba(255, 255, 255, .5);
  height: 35px;
}

.Slide-circle-3-line {
  display: none;
  margin-top: -14px;
  margin-left: 7px;
  width: 2px;
  background-color: rgba(255, 255, 255, .5);
  height: 35px;
}

.Slide-circle-0-text {
  font-size: 2rem;
  display: none;
  margin-top: -14px;
  margin-left: -78px;
  color: rgba(255, 255, 255, 1);
  width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 4px;
  border: 4px solid white;
  border-radius: 10px;
  filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, .25));
  transform: scale(.5)
}

.Slide-circle-1-text {
  font-size: 2rem;
  display: none;
  margin-top: -14px;
  margin-left: -92px;
  color: rgba(255, 255, 255, 1);
  width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 4px;
  border: 4px solid white;
  border-radius: 10px;
  filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, .25));
  transform: scale(.5)
}

.Slide-circle-2-text {
  font-size: 2rem;
  display: none;
  margin-top: -14px;
  margin-left: -77px;
  color: rgba(255, 255, 255, 1);
  width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 4px;
  border: 4px solid white;
  border-radius: 10px;
  filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, .25));
  transform: scale(.5)
}

.Slide-circle-3-text {
  font-size: 2rem;
  display: none;
  margin-top: -14px;
  margin-left: -90px;
  color: rgba(255, 255, 255, 1);
  width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 4px;
  border: 4px solid white;
  border-radius: 10px;
  filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, .25));
  transform: scale(.5)
}

.Testimonials-header {
  padding-top: 5%;
  color: rgba(30, 144, 238, 0.6);
}

.Testimonials-1 {
  position: relative;
  display: grid;
  justify-self: center;
  margin-top: 0%;
  margin-right: 25%;
  font: .9rem sans-serif;
  border-radius: 35px;
  padding-left: 2%;
  padding-right: 2%;
  width: 80%;
  height: 600px;
  min-width: 400px;
  max-width: 450px;
  text-align: left;
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(0, 0, 0, 0.1);
  border-left: 2px solid rgba(39, 130, 190, 0.1);
  border-bottom: 2px solid rgba(39, 130, 190, 0.1);
}

.Testimonials-2 {
  position: relative;
  display: grid;
  justify-self: center;
  margin-top: 0%;
  margin-left: 25%;
  font: .9rem sans-serif;
  border-radius: 35px;
  padding-left: 2%;
  padding-right: 2%;
  width: 80%;
  height: 600px;
  min-width: 400px;
  max-width: 450px;
  text-align: left;
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(0, 0, 0, 0.1);
  border-left: 2px solid rgba(39, 130, 190, 0.1);
  border-bottom: 2px solid rgba(39, 130, 190, 0.1);
}

.Testimonials-1-text {
  margin-top: -50px;
  margin-bottom: 10%;
  width: 80%;
  height: 250px;
  min-width: 350px;
  max-width: 350px;
  justify-self: center;
}

.Testimonials-quotation-1 {
  margin-top: 20px;
  margin-bottom: 15px;
  text-align: left;
  font-size: 5rem;
}

.Testimonials-quotation-2 {
  margin-top: -5px;
  font-size: 5rem;
  text-align: right;
}

.Testimonials-name-1 {
  font-family: 'Cormorant SC';
  font-weight: 300;
  font-size: 1.5rem;
  text-align: center;
  margin-top: -15%;
  padding: 0%;
}

#compositions-image {
  content: url(../public/hshot6blackedit.webp);
}

.Compositions-wrapper {
  margin-top: -9%;
  display: grid;
  margin-bottom: 18%;
}

.Compositions-background {
  display: block;
  max-width: 100%;
  height: auto;
  position: absolute;
  margin-left: 10;
}

.Compositions-background-picture {
  width: 15%;
  margin-left: -4%;
  margin-right: -4%;
}

.Compositions-header {
  display: grid;
  justify-content: center;
  text-align: center;
  margin-top: -15%;
  width: 100%;
  position: absolute;
  font: 17rem 'Cormorant SC';
  color: #f5dfa5;
  background: linear-gradient(#ebbc7e 1%, #eeb76f 5%,transparent 60%);
  transform: scale(.25);
  border-radius: 20px;
}

.Compositions-header-mirror {
  margin-top: -7%;
  width: 100%;
  font: 17rem 'Cormorant SC';
  color: #b39550a1;
  transform: scale(1, -1);
}

.Compositions-image {
  display: grid;
  position: relative;
  max-width: 100%;
  height: auto;
  transform: scale(1);
  margin-top: 0%;
  z-index: 2;
}

.Composition-bio {
  position: absolute;
  padding: 2%;
  max-width: 42%;
  min-width: 42%;
  height: 100%;
  margin-top: 8%;
  margin-left: 52%;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 500;
  z-index: 2;
}

.Composition-bio-text {
  position: relative;
  padding: 2%;
  max-width: 100%;
  margin-left: 0%;
  margin-bottom: -5%;
  color: rgba(0, 0, 0, .65);
  font-family: 'Poppins';
  font-size: .9rem;
  font-weight: 400;
  border-radius: 5px;
  background-color: #ffffff3b;
  text-align: left;
}

.Composition-video-title {
  justify-self: center;
  color: rgba(0, 0, 0, 0.75);
  font: 1.2em 'Cormorant SC';
  font-weight: 300;
  margin-top: 12%;
  margin-left: 10%;
  margin-bottom: 3%;
}

.Composition-video-1 {
  border-radius: 5px;
  position: relative;
  margin-left: 10%;
  margin-bottom: -5%;
  padding: 2%;
  outline: 1px solid rgba(0, 0, 0, 0.15);
}

.Composition-video-2 {
  border-radius: 5px;
  position: relative;
  margin-left: 10%;
  padding: 2%;
  outline: 1px solid rgba(0, 0, 0, 0.15);
}

.Performances-wrapper {
  margin-top: -20%;
  display: grid;
  z-index: 2;
}

#Performances-image {
  content: url(../public/Performances.webp);
}

.Performances-image {
  display: block;
  position: relative;
  max-width: 100%;
  height: auto;
  z-index: 1;
}

.Performances-flex {
  height: calc(100% - 250px);
  width: 90%;
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0;
}

.Performances-bio {
  display: flex;
  flex-direction: column;
  z-index: 2;
  padding: 2%;
  width: 100%;
  height: 90%;
  color: rgba(0, 0, 0, 0.5);
}

.Performances-video-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Performances-header {
  position: absolute;
  font: 10rem 'Tangerine';
  color: rgba(255, 255, 255, 0.9);
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  transform: scale(.5);
  padding-top: 2%;
  padding-bottom: 2%;
  margin-top: 2%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 3;
}

.Performances-bio-text {
  height: fit-content;
  width: 50%;
  z-index: 3;
  text-align: left;
  padding: 2%;
  margin-top: 4%;
  color: rgba(255, 255, 255, 0.5);
  font-size: 1rem;
  font-weight: 400;
  border-radius: 5px;
  background-color: #00000091;
  outline: 1px solid rgba(88, 88, 88, 0.15);
}

.Performances-video {
  width: 61.8%;
  height: 100%;
  border-radius: 5px;
  position: relative;
  padding: 2%;
  background-color: #00000091;
  outline: 1px solid rgba(88, 88, 88, 0.15);
}

.Performances-video-title {
  color: rgba(255, 255, 255, 0.9);
  font: 1.2em 'Cormorant SC';
  margin-bottom: 20px;
}

.Contact {
  display: grid;
  z-index: 8;
  width: 100%;
  height: -50%;
  background: radial-gradient(rgb(12, 13, 20), black), url(https://grainy-gradients.vercel.app/noise.svg);
  background-size: cover;
}

.Contact-wrapper {
  max-width: 600px;
  width: 100%;
  justify-self: center;
  z-index: 2;
  margin-top: 10%;
  font: 1em sans-serif;
  padding: 10px;
  border-radius: 15px;
}

.Contact-header {
  font: 3rem 'Poppins';
  color: rgb(255, 255, 255)
}

.Contact-footer {
  justify-self: left;
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 10px;
  margin-left: 3%;
  margin-bottom: 2%; 
}

#name {
  width: 90%;
  justify-self: center;
  border: 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  padding: 2%;
  margin-bottom: 2%;
  margin-top: 2%;
  outline: none;
  background-color: rgba(204, 204, 204, 0.1);
  color: rgb(255, 255, 255)
}

#name::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

#email {
  width: 90%;
  justify-self: center;
  border: 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  padding: 2%;
  margin-bottom: 2%;
  outline: none;
  background-color: rgba(204, 204, 204, 0.1);
  color: rgb(255, 255, 255)
}

#email::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

#subject {
  width: 90%;
  justify-self: center;
  border: 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  padding: 2%;
  margin-bottom: 2%;
  outline: none;
  background-color: rgba(204, 204, 204, 0.1);
  color: rgb(255, 255, 255)
}

#subject::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

#message {
  width: 90%;
  padding: 2%;
  justify-self: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font: 1em sans-serif;
  outline: none;
  background-color: rgba(204, 204, 204, 0.1);
  color: rgb(255, 255, 255)
}

#message::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

#submit {
  width: 94%;
  margin-bottom: 5%;
  justify-self: center;
  color: rgb(255, 255, 255);
  font-size: 1rem;
  background: linear-gradient(rgb(45, 187, 71), rgb(1, 116, 1));
  outline: 1px solid rgb(0, 129, 11);
  border: 0px;
  padding: 2%;
}

#submit:active {
  background: linear-gradient(rgb(1, 116, 1), rgb(45, 187, 71));
}

.Contact-form {
  width: 100%;
  display: grid;
  margin-bottom: 0%;
}

@keyframes popup {
  0%   {transform: scale(0);}
  3% {transform: scale(1);}
  87% {transform: scale(1);}
  90%   {transform: scale(0);}
  100%   {transform: scale(0);}
}

.Modal {
  display: grid;
  z-index: 3;
}

.Modal-wrapper-success {
  border-radius: 15px;
  margin-top: 10%;
  position: absolute;
  justify-self: center;
  animation-name: popup;
  animation-duration: 5.5s;
  width: 200px;
  height: 100px;
  color: rgb(19, 61, 21);
  text-align: center;
  background: radial-gradient(rgb(111, 201, 106), rgb(64, 126, 62) 95%, rgb(111, 201, 106));
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
}

.Modal-wrapper-failed {
  border-radius: 15px;
  margin-top: 10%;
  position: absolute;
  justify-self: center;
  animation-name: popup;
  animation-duration: 5.5s;
  width: 200px;
  height: 100px;
  color: rgb(61, 19, 19);
  text-align: center;
  background: radial-gradient(rgb(201, 106, 106), rgb(126, 62, 62) 95%, rgb(201, 106, 106));
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
}

.Modal-text {
  margin-top: 15%;
  font-size: 1rem;
  font-weight: bold;
  justify-items: center;
}

input[name='name'] {
  width: 300px;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
}

input[name='email'] {
  width: 300px;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
}

textarea {
  width: 600px;
  height: 300px;
  padding: 12px 20px;
  margin: 8px 0;
}

/* DESKTOP */

@media screen and (min-width: 1921px) {
    .wrapper {
      width: 100vw;
    }

    .Navbar {
      width: 1920px;
    }

    .Intro-wrapper {
      width: 1940px;
    }

    .About {
      max-width: 1920px;
    }

    .Lessons {
      max-width: 1920px;
    }

    .Lessons-rundown {
      max-width: 1600px;
    }

    .Lessons-grid-1 {
      max-width: 1920px;
    }

    .Lessons-slide-wrapper {
      max-width: 1920px;
    }

    .Lessons-slide-pictures {
      max-width: 1920px;
    }

    .Lessons-slide-text-0 {
      position: relative;
      transform: translateY(-500%);
      justify-items: center;
      margin: 0%;
      margin-top: -9.5%;
      color: rgba(255, 255, 255, 0.9);
      font-size: 1rem;
      text-align: left;
      border-radius: 0px;
      max-width: 100%;
      filter: drop-shadow(0px 0px 10px rgb(90, 90, 90));
      background: linear-gradient(transparent, rgba(136, 136, 136, 0.1) 99%);
      background-color: transparent;
    }
    
    .Lessons-slide-text-1 {
      position: relative;
      transform: translateY(-500%);
      justify-items: center;
      margin: 0%;
      margin-top: -9.5%;
      color: rgba(255, 255, 255, 0.9);
      font-size: 1rem;
      text-align: left;
      border-radius: 0px;
      max-width: 100%;
      filter: drop-shadow(0px 0px 10px rgb(90, 90, 90));
      background: linear-gradient(transparent, rgba(136, 136, 136, 0.1) 99%);
      background-color: transparent;
    }
    
    .Lessons-slide-text-2 {
      position: relative;
      transform: translateY(-650%);
      justify-items: center;
      margin: 0%;
      margin-top: -7.5%;
      color: rgba(255, 255, 255, 0.9);
      font-size: 1rem;
      text-align: left;
      border-radius: 0px;
      max-width: 100%;
      filter: drop-shadow(0px 0px 10px rgb(90, 90, 90));
      background: linear-gradient(transparent, rgba(136, 136, 136, 0.1) 99%);
      background-color: transparent;
    }

    .Lessons-slide-text-3 {
      position: relative;
      transform: translateY(-500%);
      justify-items: center;
      margin: 0%;
      margin-top: -9.5%;
      color: rgba(255, 255, 255, 0.9);
      font-size: 1rem;
      text-align: left;
      border-radius: 0px;
      max-width: 100%;
      filter: drop-shadow(0px 0px 10px rgb(90, 90, 90));
      background: linear-gradient(transparent, rgba(136, 136, 136, 0.1) 99%);
      background-color: transparent;
    }

    .Compositions-wrapper {
      max-width: 1920px;
    }

    .Compositions-header {
      display: none;
    }
  
    .Compositions-header-mirror {
      margin-top: -7.5%;
      width: 100%;
      font: 17rem 'Cormorant SC';
      color: #b39550a1;
      transform: scale(1, -1);
    }

    .Performances-wrapper {
      max-width: 1920px;
    }

    .Contact {
      max-width: 1920px;
    }
  }

@media only screen and (max-width: 1800px) {
  .Lessons-slide-text-1 {
    max-width: 65%;
    margin-top: -6%;
    margin-left: 28.25%;
  }

  .Performances-bio-text {
    font-size: .9rem;
  }
}

@media only screen and (max-width: 1740px) {
  .About-bio {
    max-width: 45%;
  }

  .Compositions-header {
    margin-top: -16%;
  }

  .Compositions-header-mirror {
    margin-top: -7.5%;
    width: 100%;
    font: 17rem 'Cormorant SC';
    color: #b39550a1;
    transform: scale(1, -1);
  }
}

@media only screen and (max-width: 1680px) {
  .plato {
    margin-left: 70%;
    margin-top: 25%;
  }

  .Lessons-slide-text-0 {
    max-width: 40%;
    margin-top: -24%;
    margin-left: 23.5%;
  }

  .Lessons-slide-text-1 {
    max-width: 60%;
    margin-top: -7%;
    margin-left: 28.25%;
  }

  .Lessons-slide-text-2 {
    max-width: 25%;
    margin-top: -24%;
    margin-left: 40%;
  }

  .About-bio {
    max-width: 50%;
    padding-bottom: 10%;
    top: -4%;
  }

  .Compositions-header {
    margin-top: -17.5%;
    margin-left: -5%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .Compositions-header-mirror {
    margin-top: -8%;
    width: 100%;
    font: 17rem 'Cormorant SC';
    color: #b39550a1;
    transform: scale(1, -1);
  }
}

@media only screen and (max-width: 1600px) {

  .Compositions-header {
    margin-top: -18%;
    margin-left: -5%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .Compositions-header-mirror {
    margin-top: -9%;
    width: 100%;
    font: 17rem 'Cormorant SC';
    color: #b39550a1;
    transform: scale(1, -1);
  }

  .Composition-video-1 {
    height: 20%;
    border-radius: 5px;
    position: relative;
    margin-left: 10%;
    margin-bottom: -5%;
    padding: 2%;
    outline: 1px solid rgba(0, 0, 0, 0.15);
  }
  
  .Composition-video-2 {
    border-radius: 5px;
    position: relative;
    margin-left: 10%;
    padding: 2%;
    outline: 1px solid rgba(0, 0, 0, 0.15);
  }
}

@media only screen and (max-width: 1550px) {

  .About-bio {
    max-width: 55%;
  }

  .Lessons-grid-1 {
    margin-top: -8%;
  }

  .Lessons-slide-text-1 {
    max-width: 60%;
    margin-top: -8%;
    margin-left: 28.25%;
  }
  
  .Lessons-slide-text-2 {
    max-width: 25%;
    margin-top: -22%;
    margin-left: 40%;
  }
  
  .Compositions-header {
    margin-top: -19%;
    margin-left: -8%;
    padding-left: 8%;
    padding-right: 8%;
  }
  
  .Compositions-header-mirror {
    margin-top: -9%;
    width: 100%;
    font: 17rem 'Cormorant SC';
    color: #b39550a1;
    transform: scale(1, -1);
  }
  
  .Performances-bio {
    height: 85%;
  }
}

@media only screen and (max-width: 1492px) {
  
  .Lessons-slide-text-0 {
    max-width: 50%;
    margin-top: -23.5%;
    margin-left: 28%;
  }
  
  .About-bio {
    margin-top: -1%;
    max-width: 55%;
    padding-bottom: 12%;
  }

  .Compositions-header {
    margin-top: -20%;
    margin-left: -10%;
    padding-left: 10%;
    padding-right: 10%;
  }

  .Compositions-header-mirror {
    margin-top: -9%;
    width: 100%;
    font: 17rem 'Cormorant SC';
    color: #b39550a1;
    transform: scale(1, -1);
  }
  
}



@media only screen and (max-width: 1440px) {

  .About {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
    height: 100%;
    position: relative;
    text-align: center;
    transform: scale(1);
    background-color: #000000;
    outline: 10px solid black;
  }
  
  .About-image {
    content: url(../public/waterfall-tall.webp);
    width: 100%;
    height: auto;
    max-width: 200%;
    max-height: 200%;
    transform: scale(1);
  }
  
  .About-bio {
    padding-top: 0%;
    padding-bottom: 0%;
    padding-left: 5%;
    padding-right: 5%;
    font-size: 1rem;
    position: relative;
    max-width: 150%;
    max-height: 150%;
    height: 100%;
    transform: scale(1);
    border: none;
    background-color: rgba(0, 0, 0, 1);
    filter: drop-shadow(0px 0px 5px #000000);
    margin-top: 4%;
    justify-self: center;
    overflow: visible;
  }
  
  .Compositions-header {
    margin-top: -21%;
    margin-left: -10%;
    padding-left: 10%;
    padding-right: 10%;
  }
  
  .Compositions-header-mirror {
    margin-top: -9%;
    width: 100%;
    font: 17rem 'Cormorant SC';
    color: #b39550a1;
    transform: scale(1, -1);
  }
  
  .Composition-bio-text {
    padding: 2%;
    width: 100%;
    max-width: 100%;
    margin-left: -2%;
    margin-bottom: -8%;
    font-weight: 500;
    border-radius: 5px;
    background-color: #ffffff3b;
  }
}

@media only screen and (max-width: 1400px) {
  .Lessons-slide-text-0 {
    max-width: 50%;
    margin-top: -22%;
    margin-left: 28%;
  }
}

@media only screen and (max-width: 1366px) {
  .Lessons-slide-text-1 {
    max-width: 50%;
    margin-top: -9%;
    margin-left: 28.25%;
  }

  .Lessons-slide-text-2 {
    max-width: 50%;
    margin-top: -8%;
    margin-left: 30%;
  }

  .Testimonials-1 {
    position: relative;
    display: grid;
    justify-self: center;
    margin-top: 5%;
    margin-right: 40%;
    font: .9rem sans-serif;
    border-radius: 35px;
    padding-left: 2%;
    padding-right: 2%;
    width: 80%;
    min-width: 400px;
    max-width: 450px;
    text-align: left;
    color: rgba(255, 255, 255, 0.5);
    background-color: rgba(0, 0, 0, 0.1);
    border-left: 2px solid rgba(39, 130, 190, 0.1);
    border-bottom: 2px solid rgba(39, 130, 190, 0.1);
  }
  
  .Testimonials-2 {
    position: relative;
    display: grid;
    justify-self: center;
    margin-top: 5%;
    margin-left: 40%;
    font: .9rem sans-serif;
    border-radius: 35px;
    padding-left: 2%;
    padding-right: 2%;
    width: 80%;
    min-width: 400px;
    max-width: 450px;
    text-align: left;
    color: rgba(255, 255, 255, 0.5);
    background-color: rgba(0, 0, 0, 0.1);
    border-left: 2px solid rgba(39, 130, 190, 0.1);
    border-bottom: 2px solid rgba(39, 130, 190, 0.1);
  }

  #compositions-image {
    content: url(../public/hshot6blackedit-laptop.webp);
  }

  .Compositions-header {
    margin-top: -22%;
    margin-left: -13%;
    padding-left: 13%;
    padding-right: 13%;
  }

  .Compositions-header-mirror {
    margin-top: -9%;
    width: 100%;
    font: 17rem 'Cormorant SC';
    color: #b39550a1;
    transform: scale(1, -1);
  }

  .Testimonial {
    width: 250%;
    height: fit-content;
    font: 1rem 'Segoe UI';
    font-style: italic;
    color: white;
    margin-top: 140%;
    margin-left: -10%;
    background-color: rgba(175, 173, 73, 0.8);
    padding-top: 10%;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 2%;
    outline: 2px solid rgb(216, 216, 216);
    transform: scale(.85);
  }

  .Performances-bio {
    height: 80%;
  }
}
@media only screen and (max-width: 1359px) {
  .quote-words {
    font-size: 1.5rem;
  }

  .quotations-2 {
    margin-top: -5%;
  }

  .Composition-bio-text {
    margin-left: -15%;
    max-width: 110%;
    width: 110%;
  }

  .Composition-bio {
    height: 90%;
  }
}

@media only screen and (max-width: 1297px) {

  .Lessons-slide-text-0 {
    font-size: 1rem;
    margin-top: -22%;
    margin-left: 28%;
  }

  .Lessons-slide-text-1 {
    font-size: 1rem;
    margin-top: -9%;
    margin-left: 28.25%;
  }
  
  .Lessons-slide-text-2 {
    font-size: 1rem;
  }

  .About {
    display: grid;
    grid-template-columns: 40% 60%;
    width: 100%;
    height: 100%;
    position: relative;
    text-align: center;
    transform: scale(1);
    outline: 10px solid black;
    background-color: black;
  }
  
  .About-image {
    position: relative;
    content: url(../public/waterfall-tall.webp);
    width: 100%;
    height: auto;
    justify-content: center;
    max-width: 90%;
    max-height: 100%;
    transform: scale(1);
    margin-top: 5%;
    margin-left: 5%;
    border-left: 10px solid black;
    border-right: 10px solid black;
  }
  
  .About-bio {
    padding-top: 0%;
    padding-bottom: 0%;
    padding-left: 5%;
    padding-right: 5%;
    font-size: 1rem;
    position: relative;
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    transform: scale(1);
    border: none;
    background-color: rgba(0, 0, 0, 1);
    filter: drop-shadow(0px 0px 5px #000000);
    margin-top: 4%;
    justify-self: center;
    overflow: visible;
  }

  .Performances-bio-text {
    width: 60%;
  }
}

@media only screen and (max-width: 1280px) {

  .Lessons-slide-text-3 {
    padding: 20px;
    max-width: 50%;
    margin-top: -10%;
    margin-left: 28%;
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: 1;
    color: rgba(255, 255, 255, 0.75);
    font-size: 1rem;
    font-family: 'Poppins';
    font-weight: 200;
    filter: drop-shadow(0px 0px 50px rgba(255, 255, 255, 0.5));
    background-color: rgba(163, 163, 163, 0.1);
  }

  .Compositions-header {
    margin-top: -23.5%;
    margin-left: -19%;
    padding-left: 19%;
    padding-right: 19%;
  }

  .Compositions-header-mirror {
    margin-top: -9%;
    width: 100%;
    font: 17rem 'Cormorant SC';
    color: #b39550a1;
    transform: scale(1, -1);
  }
}

@media only screen and (max-width: 1200px) {
  .Intro-header {
    font-size: 6rem;
  }

  .Lessons-slide-text-0 {
    margin-top: -22%;
    margin-left: 28%;
  }

  .Compositions-header {
    margin-top: -26%;
    margin-left: -24%;
    padding-left: 24%;
    padding-right: 24%;
  }

  .Composition-bio {
    padding: 2%;
    max-width: 42%;
    min-width: 42%;
    height: 100%;
    margin-left: 52%;
    color: rgba(0, 0, 0, 0.5);
    font-size: .8em;
    font-weight: 500;
    z-index: 2;
  }
  
  .Composition-bio-text {
    position: relative;
    padding: 2%;
    max-width: 110%;
    width: 110%;
    margin-left: -10%;
    margin-bottom: -5%;
    font-weight: 500;
    border-radius: 5px;
    background-color: #ffffff3b;
  }

  #composition-1 {
    position: absolute;
    font-size: 1rem;
    margin-left: 6%;
    margin-top: 20%;
  }

  #composition-2 {
    position: absolute;
    font-size: 1rem;
    margin-left: 62%;
    margin-top: 20%;
  }

  .Composition-video-1 {
    border-radius: 5px;
    width: 45.5%;
    height: auto;
    position: absolute;
    margin-left: -55%;
    margin-top: 25%;
    padding: 2%;
    outline: 1px solid rgba(0, 0, 0, 0.15);
  }
  
  .Composition-video-2 {
    border-radius: 5px;
    width: 45.5%;
    height: auto;
    position: absolute;
    margin-left: -0%;
    margin-top: 25%;
    padding: 2%;
    outline: 1px solid rgba(0, 0, 0, 0.15);
  }

  .Performances-flex {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    top: 0;
    bottom: none;
    margin-top: 175px;
  }

  .Performances-image {
    height: 200%;
  }

  .Performances-bio {
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 20%;
  }

  .Performances-bio-text {
    width: 90%;
  }

  .Performances-bio div {
    width: 100%;
    height: 100%;
  }

  .Performances-bio iframe {
    height: 100%;
  }
}

@media only screen and (max-width: 1147px) {

  .Lessons-grid-1 {
    justify-content: center;
    display: grid;
    margin-bottom: 25%;
  }

  .Lessons-slide-wrapper {
    display: grid;
    justify-content: center;
    grid-template-columns: auto 80% auto;
  }

  .Lessons-slide-grid {
    position: grid;
    justify-items: center;
  }


  @keyframes slide-bar {
    0% {
      width: 0%;
    }
  
    100% {
      width: 80%;
    }
  }

  .Slide-progress {
    margin-left: 10%;
  }

  .Lessons-slide-text-0 {
    position: relative;
    transform: translateY(-100.5%);
    justify-items: center;
    margin: 0%;
    margin-top: -28%;
    color: rgba(255, 255, 255, 0.9);
    font-size: .9rem;
    text-align: left;
    border-radius: 0px;
    max-width: 100%;
    filter: drop-shadow(0px 0px 10px rgb(90, 90, 90));
    background: rgba(136, 136, 136, 0.1);
  }

  .Lessons-slide-text-1 {
    position: relative;
    transform: none;
    justify-items: center;
    margin: 0%;
    margin-top: -28%;
    color: rgba(255, 255, 255, 0.9);
    font-size: .9rem;
    text-align: left;
    border-radius: 0px;
    max-width: 100%;
    filter: drop-shadow(0px 0px 10px rgb(90, 90, 90));
    background: rgba(136, 136, 136, 0.1);
  }

  .Lessons-slide-text-2 {
    position: relative;
    transform: translateY(-168.5%);
    justify-items: center;
    margin: 0%;
    margin-top: -20.9%;
    color: rgba(255, 255, 255, 0.9);
    font-size: .9rem;
    text-align: left;
    border-radius: 0px;
    max-width: 100%;
    filter: drop-shadow(0px 0px 10px rgb(90, 90, 90));
    background: rgba(136, 136, 136, 0.1);
  }

  .Lessons-slide-text-3 {
    position: relative;
    transform: translateY(0%);
    justify-items: center;
    margin: 0%;
    margin-top: -28%;
    color: rgba(255, 255, 255, 0.9);
    font-size: .9rem;
    text-align: left;
    border-radius: 0px;
    max-width: 100%;
    filter: drop-shadow(0px 0px 10px rgb(90, 90, 90));
    background: rgba(136, 136, 136, 0.1);
  }

  .Lessons-slide-pictures {
    margin-bottom: -4px;
    filter: opacity(1);
  }

}

@media only screen and (max-width: 1120px) {

  .plato {
    font-size: 3rem;
    margin-top: 24%;
  }

  .Compositions-header {
    margin-top: -28%;
    margin-left: -30%;
    padding-left: 30%;
    padding-right: 30%;
  }

  .Performances-bio-text {
    font-size: .9rem;
    width: 100%;
  }

  .Performances-flex {
    flex-direction: row;
    align-items: flex-start;
    margin-top: 200px;
  }

  .Performances-bio {
    flex-direction: column;
  }
}

@media only screen and (max-width: 1024px) {

  .Composition-bio {
    position: absolute;
    padding: 2%;
    max-width: 45%;
    min-width: 45%;
    height: 100%;
    margin-left: 52%;
    color: rgba(0, 0, 0, 0.5);
    font-size: .8em;
    font-weight: 500;
    z-index: 2;
  }
  
  .Composition-bio-text {
    position: relative;
    padding: 2%;
    max-width: 100%;
    margin-left: -10%;
    margin-bottom: -10%;
  }

  .Composition-video-1 {
    border-radius: 5px;
    width: 35%;
    height: 15%;
    position: absolute;
    margin-left: -55%;
    margin-top: 25%;
    padding: 2%;
    outline: 1px solid rgba(0, 0, 0, 0.15);
  }
  
  .Composition-video-2 {
    border-radius: 5px;
    width: 35%;
    height: 15%;
    position: absolute;
    margin-left: -0%;
    margin-top: 25%;
    padding: 2%;
    outline: 1px solid rgba(0, 0, 0, 0.15);
  }

  .Composition-video-title {
    color: rgba(0, 0, 0, 0.75);
    font: 1.2em 'Cormorant SC';
    font-weight: 300;
    left: -5px;
    margin-top: 12%;
    margin-left: 10%;
    margin-bottom: 3%;
  }

  .Compositions-header {
    margin-top: -30%;
    margin-left: -35%;
    padding-left: 35%;
    padding-right: 35%;
  }

  .Performances-flex {
    margin-top: 175px;
  }

  .Performances-video-title {
    margin-bottom: 0px;
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media only screen and (max-width: 962px) {

  .Mobile {
    transition: 0.5s;
    display: grid;
    justify-content: center;
    position: fixed;
    grid-template-rows: 40px;
    margin-left: 10px;
    width: 150px;
    height: 80px;
    z-index: 9;
    cursor: pointer;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .Mobile:active {
    background-color: rgb(119, 255, 248);
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .Mobile.active {
    transition: 0.5s;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    background-color: rgba(0, 0, 0, 1);
  }

  .arrow {
    position: relative;
    padding-left: -20px;
    color: white;
    border-radius: 8px;
    transition: 0.5s;
    margin-left: 20%;
    filter: drop-shadow(0px 0px 2px black);
  }

  .arrow.active {
    transform: rotate(180deg);
  }

  #menu {
    font: 1rem 'Poppins';
    font-weight: 400;
    color: white;
    margin-right: 15%;
    filter: drop-shadow(0px 0px 2px black);
  }

  .Navbar {
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    z-index: 9;
  }

  .Navbar-menu {
    display: grid;
    position: fixed;
    width: 100%;
    height: 55px;
    top: -100%;
    margin-top: 0px;
    background-color: transparent;
    color: rgb(255, 255, 255);
    text-align: center;
    transition: .5s;
    justify-items: left;
  }

  .Navbar-menu.active {
    display: grid;
    position: fixed;
    width: 100%;
    height: 55px;
    top: 80px;
    margin-top: 0px;
    flex-direction: row;
    background-color: transparent;
    color: rgb(255, 255, 255);
    text-align: center;
    transition: .5s;
  }
  
  .Link {
    width: 150px;
    height: 55px;
    font: 1rem 'Poppins';
    font-weight: 100;
    text-align: center;
    word-wrap: break-word;
    margin-left: 10px;
    padding-right: 0%;
    padding-left: 0%;
    padding-top: 0%;
    padding-bottom: 0%;
    cursor: pointer;
    transition: 0.5s;
    animation-direction: reverse;
    background-color: rgba(0, 0, 0, 1);
    margin-bottom: -2px;
  }

  #Link-6 {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .About {
    display: block;
    justify-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    text-align: center;
    transform: scale(1);
    outline: 10px solid black;
    background-color: black;
  }

  .About-image {
    content: url(../public/waterfall-wide.webp);
    width: 100%;
    margin-left: 0%;
    margin-bottom: 20px;
  }

  .About-bio {
    padding-top: 0%;
    padding-bottom: 0%;
    padding-left: 5%;
    padding-right: 5%;
    font-size: 1rem;
    position: relative;
    max-height: 100%;
    height: 50%;
    transform: scale(1);
    border: none;
    background-color: rgba(0, 0, 0, .5);
    filter: drop-shadow(0px 0px 5px #000000);
    margin-top: 4%;
    justify-self: center;
    overflow: visible;
  }

  .Teaching-header {
    background-color: rgba(0, 0, 0, 0.25);
    padding: 2%;
  }

  .plato-pic {
    width: 100%;
    margin-left: -30%;
    margin-top: -25%; 
    content: url(../public/plato5.webp);
    position: absolute;
    transform: scale(1);
    filter: drop-shadow(10px 20px 4px #00000070) grayscale(100%);
    z-index: -1;
    filter: sepia(100%) contrast(75%);
  }
  
  .plato-quote {
    width: 100%;
    height: fit-content;
    background-repeat: no-repeat;
  }
  
  .quote-wrapper {
    display: grid;
    margin-top: 12%;
    justify-self: center;
    padding-top: 1%;
    height: 100%;
    width: 100%;
    background-image: none;
    transform: scale(.8);
    outline: none;
  }
  
  .quote-words {
    left: 0; 
    right: 0; 
    margin-left: 35%; 
    margin-right: auto;
    width: 60%;
    height: 17%;
    position: absolute;
    padding: 5%;
    padding-bottom: 5%;
    margin-top: -15%;
    font: 2rem 'Belleza';
    border-radius: 25px;
    color: #ffc476;
    background-color: transparent;
    z-index: -2;
  }

  .plato {
    position: absolute;
    font: 3rem 'Tangerine';
    margin-left: 80%;
    margin-top: 15%;
    color: #ffc476;
  }

  .quote-text {
    border-radius: 15px;
    background: rgba(0, 0, 0, 0);
  }
  
  .quotations-1 {
    position: absolute;
    margin: 0;
    margin-top: -2%;
    margin-left: -6%;
    justify-content: left;
    font: 5rem 'Belleza';
  }
  
  .quotations-2 {
    position: absolute;
    margin: 0;
    margin-top: -7%;
    margin-left: 87%;
    justify-content: right;
    font: 5rem 'Belleza';
  }
  
  .Lessons-grid-2 {
    grid-template-columns: 100%;
  }

  .Testimonials-1 {
    width: 100%;
    margin-right: 0%;
  }

  .Testimonials-2 {
    width: 100%;
    margin-left: 0%;
  }

  #quotation-2 {
    margin-top: -14%
  }

  .Lessons-rundown {
    font-size: 1rem;
    position: relative;
    width: 75%;
    color: #f1d27ca8;
    padding: 2%;
    height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .Lessons-rundown::-webkit-scrollbar {
    width: 10px;
  }
   
  .Lessons-rundown::-webkit-scrollbar-thumb {
    background-color: rgba(169, 169, 169, 0.2);
    border-radius: 5px;
  }

  .Lessons-header {
    font: 8.5rem 'Cormorant SC';
    color: rgba(30, 144, 238, 0.6);
    transform: scale(.5);
    margin-top: 10%;
    margin-bottom: -1%;
  }

  .Lessons-grid-1 {
    width: 700px;
  }

  .Lessons-slide-text-0 {
    transform: translateY(0%);
    margin-top: 0px;
    height: 140px;
  }

  .Lessons-slide-text-1 {
    transform: translateY(0%);
    margin-top: 0px;
    height: 140px;
  }

  .Lessons-slide-text-2 {
    transform: translateY(0%);
    margin-top: 0px;
    height: 140px;
  }

  .Lessons-slide-text-3 {
    transform: translateY(0%);
    margin-top: 0px;
    height: 140px;
  }

  .Compositions-wrapper {
    margin-top: 7%;
  }

  .Compositions-header {
    filter: saturate(150%);
    background: none;
    margin-top: -47%;
  }

  #compositions-image {
    transform: scale(1.5);
  }

  .Compositions-image {
    display: grid;
    grid-template-columns: 100%;
  }

  .Composition-bio {
    display: grid;
    margin: 0;
    justify-self: right;
    margin-top: -10%;
    margin-right: 4%;
  }
  
  .Composition-bio-text {
    margin: 0;
  }

  #composition-1 {
    position: relative;
    justify-self: center;
    text-align: center;
    margin: 0%;
    margin-top: 5%;
    margin-block-end: 5px;
  }

  .Composition-video-1 {
    position: relative;
    justify-self: center;
    margin: 0%;
    width: 80%;
    height: 100%;
  }

  #composition-2 {
    position: relative;
    justify-self: center;
    text-align: center;
    margin: 0%;
    margin-top: 10%;
    margin-block-end: 5px;
  }

  .Composition-video-2 {
    position: relative;
    justify-self: center;
    margin: 0%;
    width: 80%;
    height: 100%;
  }

  .Performances-header {
    margin-top: 2%;
  }

  .Performances-wrapper {
    margin-top: -5%;
    margin-bottom: 20%;
  }

  .Performances-image {
    display: grid;
    justify-items: center;
    grid-template-columns: 100%;
    height: 200%;
  }

  #Performances-image {
    transform: scale(2);
  }
}

@media only screen and (max-width: 906px) {
  .Compositions-wrapper {
    margin-top: 12%;
  }

  #compositions-image {
    transform: scale(1.75);
    margin-right: -5%;
  }

  .Compositions-header {
    margin-top: -495px;
  }

  .Composition-bio {
    margin-top: -15%;
  }

  .Compositions {
    margin-bottom: 10%;
  }

  .Performances-wrapper {
    margin-top: -6%;
    margin-bottom: 50%;
  }

  .Performances-flex {
    flex-direction: column-reverse;
    align-items: center;
  }

  .Performances-bio-text {
    width: 90%;
  }

}

@media only screen and (max-width: 875px) {
  .Compositions-header {
    margin-top: -485px;
  }

  .plato {
    margin-top: 22%;
  }
}

@media only screen and (max-width: 817px) {
  .Composition-bio {
    min-width: none;
    max-width: none;
    width: 50%;
    margin-right: 8%;
  }

  .Compositions-header {
    margin-top: -470px;
  }
  
  #compositions-image {
    margin-left: -10%;
  }

  .Composition-video-1 {
    height: 90%;
  }

  .Composition-video-2 {
    height: 90%;
  }

  .Performances-flex {
    flex-direction: row;
  }
}

@media only screen and (max-width: 750px) {
  .Teaching-header {
    margin-top: 40%;
    background-color: rgba(255, 255, 255, 0.25);
  }

  .plato {
    margin-top: 30%;
  }

  .Lessons-grid-1 {
    justify-content: center;
    display: grid;
    margin-bottom: 25%;
    width: 100%;
  }

  .Lessons-slide-wrapper {
    display: grid;
    justify-content: center;
    grid-template-columns: 100%;
  }

  .Lessons-slide-grid {
    position: grid;
    justify-items: center;
    border: none;
  }

  .Lessons-slide-text-0 {
    transform: none;
    height: fit-content;
    margin-top: 0px;
    margin-block-end: 5%;
    filter: none;
  }

  .Lessons-slide-text-1 {
    transform: none;
    height: fit-content;
    margin-top: 0px;
    margin-block-end: 5%;
    filter: none;
  }

  .Lessons-slide-text-2 {
    transform: none;
    height: fit-content;
    margin-top: 0px;
    margin-block-end: 5%;
    filter: none;
  }

  .Lessons-slide-text-3 {
    transform: none;
    height: fit-content;
    margin-top: 0px;
    margin-block-end: 5%;
    filter: none;
  }

  .Slide-circles {
    display: none;
  }

  .Slide-progress {
    display: none;
  }

  .Testimonials-1 {
    border: none;
    border-radius: 0;
  }

  .Testimonials-2 {
    border: none;
    border-radius: 0;
  }

  .Compositions-header {
    margin-top: -455px;
  }

  #Performances-image {
    transform: scale(3);
    margin-top: 20%;
  }
}

@media only screen and (max-width: 709px) {

  .quote-words {
    font-size: 1.25rem;
    margin-top: -5%;
  }

  .plato {
    font-size: 2.5rem;
  }
}

@media only screen and (max-width: 700px) {
  .Intro-wrapper {
    min-height: 400px;
  }

  #compositions-image {
    transform: scale(2);
    margin-top: 8%;
  }

  .Performances-wrapper {
    margin-top: 0%;
  }
}

@media only screen and (max-width: 667px) {
  
}

@media only screen and (max-width: 650px) {
  .quotations-1 {
    margin-left: -10%;
  }

  .plato {
    margin-top: 33%
  }

  .Compositions-header {
    margin-top: -445px;
  }

  .Composition-bio {
    margin-right: 2%;
    width: 60%;
  }

}

@media only screen and (max-width: 608px) {
  .plato {
    margin-left: 75%;
    margin-top: 32%
  }

  .Composition-video-1 {
    width: 60%;
    height: 80%;
  }

  .Composition-video-2 {
    width: 60%;
    height: 80%;
  }

  #composition-2 {
    margin-top: 2%
  }

  .Performances-flex {
    flex-direction: column-reverse;
  }

  .Contact {
    position: relative;
    margin-block-start: 45%;
  }
}

@media only screen and (max-width: 601px) {

  .Lessons-header {
    font-size: 3rem;
    transform: scale(1);
    padding: 5%;
  }

  .Testimonials-1 {
    min-width: 0px;
    height: auto;
  }

  .Testimonials-1-text {
    min-width: 0px;
    height: auto;
  }

  .Testimonials-2 {
    min-width: 0px;
    height: auto;
  }
  
  .Testimonials-2-text {
    min-width: 0px;
    height: auto;
  }

  .plato-quote {
    display: grid
  }

  .quote-wrapper {
    margin-top: 20%;
  }

  .Compositions-header {
    width: 100%;  
  }

}

@media only screen and (max-width: 575px) {
  .Lessons-grid-1 {
    margin-bottom: 35%;
  }
  
  #compositions-image {
    transform: scale(2.5);
  }

  .Compositions-image {
    margin-top: 12%;
  }

  .Performances-wrapper {
    margin-top: 10%;
  }
  .Performances-bio-text {
    margin-top: 8%;
  }

  .Performances-video-title {
    padding: 0;
    margin-bottom: 10px;
  }

  #Performances-image {
    transform: scale(3);
    margin-top: 20%;
  }

  .Performances-header {
    margin-top: 10%;
  }
  
  .Contact {
    margin-block-start: 60%;
  }
}

@media only screen and (max-width: 537px) {
  #Performances-image {
    transform: scale(3);
    margin-top: 20%;
  }
}

@media only screen and (max-width: 535px) {
  .quote-words {
    margin-top: -15%;
  }

  .Lessons-rundown {
    width: 75%;
  }

  .Compositions-header {
    margin-top: -430px;
  }

  .Composition-bio {
    margin-top: -25%;
  }

  #Performances-image {
    margin-top: 49%;
    transform: scale(4);
  }
}

@media only screen and (max-width: 500px) {

  .Compositions-header {
    transform: scale(.2);
  }
  
  .Composition-video-1 {
    height: 60%;
  }

  .Composition-video-2 {
    height: 60%;
  }

  #composition-2 {
    margin-top: -8%;
  }
}

@media only screen and (max-width: 477px) {
  .plato {
    width: 50%;
    margin-top: 29%;
    margin-left: 62%;
  }  

  .quote-words {
    margin-top: -25%;
    margin-left: 38%;
    width: 60%;
  }

  .quotations-2 {
    margin-top: -15%;
    margin-left: 85%;
  }


  .Performances-header {
    width: 100%;
    border: 0px;
    padding: 0%;
  }

  .Contact {
    margin-block-start: 100%;
  }
}

@media only screen and (max-width: 459px) {
  .Intro-header {
    margin-top: 35%;
    font-size: 4rem;
  }

  .Intro-header-2 {
    font-size: 3rem
  }

  .Teaching-header {
    font-size: 3rem;
    padding: 5%;
  }

  #compositions-image {
    transform: scale(3);
    margin-left: 10%;
    margin-top: 22%;
  }

  .Composition-bio {
    margin-top: -28%;
    margin-right: -8%;
  }

  .Composition-bio-text {
    width: 75%;
    margin-left: 10%;
  }

  .Performances-wrapper {
    margin-top: 25%;
  }

}

@media only screen and (max-width: 437px) {
  .quote-words {
    margin-left: 35%;
    width: 65%;
  }

  .Compositions-header {
    margin-top: -420px;
  }
  
  .Composition-bio-text {
    margin-left: 0%;
    width: 85%;
  }

  .Composition-video-1 {
    height: 80px;
    margin-top: 2%;
    margin-bottom: -100%;
    margin-left: -160%;
    outline: none;
  }

  .Composition-video-2 {
    height: 80px;
    outline: none;
  }

  #composition-1 {
    color: white;
    top: 3.5%;
    left: -80%;
    margin-top: 50%;
  }

  .Performances-header {
    font-size: 5rem;
  }

  .Performances-flex {
    margin-top: 100px;
  }

  .Contact {
    margin-block-start: 107%;
  }
}

@media only screen and (max-width: 408px) {
  .quote-words {
    font-size: 1rem;
    width: 50%;
    margin-left: 40%;
  }

  .quotations-1 {
    margin-left: -17%;
    margin-top: -12%;
  }

  .plato {
    margin-top: 25%;
  }
}

@media only screen and (max-width: 391px) {
  .Composition-video-1 {
    margin-top: 3%;
  }

  .Compositions-header {
    transform: scale(.18);
    margin-top: -410px;
  }

  .Composition-bio-text {
    margin-bottom: -25%;
  }

  #Performances-image {
    margin-top: 49%;
    transform: scale(4.5);
  }

  .Contact {
    margin-block-start: 120%;
  }
}

@media only screen and (max-width: 376px) {

  .quote-wrapper {
    margin-top: 35%;
  }

  .Teaching-header {
    margin-top: 45%;
  }

  .quote-words {
    margin-top: -35%;
  }

  .plato {
    margin-top: 32%;
    font-size: 2rem;
  }

  .Composition-bio-text {
    margin-bottom: -35%;
  }

  .Composition-video-1 {
    margin-top: 4%;
    outline: none;
  }

  .Composition-video-2 {
    outline: none;
  }

  .Performances-bio-text {
    width: 96%;
    border-radius: 0px;
    font-size: .85rem;
  }
}

@media only screen and (max-width: 370px) {
  .Composition-bio-text {
    font-size: .85rem;
    margin-bottom: -40%;
  }

  .Performances-bio iframe {
    height: 100px;
  }
}

@media only screen and (max-width: 350px) {
  .Composition-bio-text {
    font-size: .8rem;
    margin-bottom: -40%;
  }

  .Composition-video-1 {
    margin-top: 5%;
  }
}


@media only screen and (max-width: 336px) {
  .Teaching-header {
    font-size: 2.5rem;
  }

  .Performances-bio iframe {
    height: 75px;
  }
}

@media only screen and (max-width: 328px) {
  .Composition-bio-text {
    margin-bottom: -50%;
  }
}

@media only screen and (max-width: 326px) {

  .Composition-video-1 {
    width: 50%;
    height: 60px;
    margin-left: -160%;
    margin-top: 6%;
  }

  .Composition-video-2 {
    width: 50%;
    height: 60px;
  }

  #composition-1 {
    left: -80%;
    top: 8%;
  }

  .Performances-video-title {
    font-size: .9rem;
  }
}

@media only screen and (max-width: 323px) {
  #performance-1 {
    margin-top: 14%;
  }
}
